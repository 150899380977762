import { API, logError } from "./index";


const getCountries = async () => {
    try {
        return await API().get('/countries');
    } catch (error) {
        return logError(error);
    }
}

const getCountryCities = async (country_id) => {
    try {
        return await API().get(`/cities?country_id=${ country_id }`);
    } catch (error) {
        return logError(error);
    }
}

const getSpecialty = async () => {
    try {
        return await API().get('/specialty');
    } catch (error) {
        return logError(error);
    }
}

const getPartnersForFilter = async () => {
    try {
        return await API().get('/all-partners');
    } catch (error) {
        return logError(error);
    }
}

const getBusinessUnits = async (partnerID) => {
    try {
        return await API().get(`/business-unit?partner_id=${partnerID}`);
    } catch (error) {
        return logError(error);
    }
}

const getSubBusinessUnit = async (businessUnitID) => {
    try {
        return await API().get(`/sub-business-unit?businessUnit_id=${businessUnitID}`);
    } catch (error) {
        return logError(error);
    }
}


export {
    getCountries,
    getCountryCities,
    getSpecialty,
    getPartnersForFilter,
    getBusinessUnits,
    getSubBusinessUnit
}